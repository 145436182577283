import React, {useContext} from 'react'
import { products } from '../global/cartUtils'
import { useParams } from 'react-router-dom'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import ShopifySection from '../components/ShopifySection/ShopifySection';
import ScrollToTop from '../global/ScrollToTop'
import CartContext from '../global/CartContext'
import PhotoSelector from '../components/PhotoSelector/PhotoSelector'

export default function Product() {
    const { handle } = useParams();
    const product = products.find((p) => p.handle === handle);

    const { cart, addToCart } = useContext(CartContext);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <>
        <ScrollToTop />
        <Header />
        <section>
            <div className='container__product'>
                <h5 className='product__path'>Flowers Singapore / {product.type} /  <strong>{product.name}</strong></h5>
                <div className='product__main-block'>
                    <div className='product__images-block'>
                        <PhotoSelector photos={product.img} />
                        <div className='product__min-images-block'>
                            {product.img.map((el) => {
                                return(
                                    <img className='product__mini-image' src={el}/>
                                )
                            })}
                        </div>
                    </div>
                    <div className='product__main-description-block'>
                        <h1 className='product__name'>{product.name}</h1>
                        <h3 className='product__price'>{formatter.format(product.price)} SGD</h3>
                        <p className='product__description'>{product.description}</p>
                        <div className='product__detail-child-block'>
                            <h4 className='product__detail-child-title'>{product.detail_child.title}</h4>
                            <p className='product__detail-child-description'>{product.detail_child.description.length > 1 ?product.detail_child.description.map((el) => {
                                return(
                                    <>
                                    <p>{el}</p>
                                    </>
                                )}) : (product.detail_child.description[0])}
                            </p>
                        </div>
                        <div className='product__detail-child-block'>
                            <h4 className='product__detail-child-title'>Dimension</h4>
                            <p className='product__detail-child-description'>{product.dimention}</p>
                        </div>
                        <button onClick={() => addToCart(product)} className='product__button-add-to-cart'>ADD TO CART</button>
                    </div>
                </div>
                <img  />
            </div>
            
            <div className='product__selector-block'>
                <ShopifySection title={'More from this collection'} description={''} type={product.type} />
            </div>
        </section>
        <Footer />
        </>
    )
}