import React from 'react'
import './Footer.css'

export default function Footer() {
  return (
    <footer>
        <h4 className='footer__text'>2024 © Flowers Singapore. All Rights Reserved.</h4>
    </footer>
  )
}
