import React from 'react'
import './ItemCartDetail.css'

export default function ItemCartDetail({data}) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div className='item-cart__detail-main-block'>
        <div className='item-cart__description-block'>
            <div className='item-cart__image-block'>
              <img className='item-cart__image-item' src={data.img[0]} />
              <div className='item-cart__qty-block'>{data.quantity}</div>
            </div>
            <div className='item-cart__name-price-block'>
              <h2 className='item-cart__name'>{data.name}</h2>
              <h3 className='item-cart__price'>{formatter.format(data.price)}</h3>
            </div>
        </div>
        <h2 className='item-cart__total-item-cart'>{formatter.format(data.price * data.quantity)}</h2>
    </div>
  )
}
