import React, {useContext} from 'react'
import './CardElement.css'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CartContext from '../../global/CartContext'

export default function CardElement({children}) {

  const { cart, addToCart } = useContext(CartContext);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <div key={children.id} className='shopify-section__card-block'>
      <Link to={'/product/'+children.handle}>
        <img className='shopify-section__card-block-img' src={children.img[0]} draggable='false' />
      </Link>
      <div className='shopify-section__card-block-footer'>
        <div className='shopify-section__card-block-footer-top'>
            <h3 className='shopify-section__card-name'>{children.name}</h3>
            <h5 className='shopify-section__card-incltax'>{formatter.format(children.inclTax)} (Incl. Tax)</h5>
        </div>
        <h4 className='shopify-section__card-price'>{formatter.format(children.price)}</h4>
        <button onClick={() => addToCart(children)} className='shopify-section__card-button'>ADD TO CART</button>
      </div>
    </div>
  )
}