import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRef, useState, useEffect } from 'react'
import InputDetail from '../components/InputDetail/InputDetail'
import visaIcon from '../assets/icon/visa-icon.svg'
import masterIcon from '../assets/icon/mastercard-icon.svg'
import amexIcon from '../assets/icon/amex-icon.svg'
import unionIcom from '../assets/icon/unionpay-icon.svg'
import ItemCartDetail from '../components/ItemCartDetail/ItemCartDetail'
import moreInfoIcon from '../assets/icon/more_info-icon.svg'
import CartContext from '../global/CartContext'
import logo from '../assets/icon/logo.svg'

export default function CheckoutPage() {
  const { cart } = useContext(CartContext);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const calculateTotal = () => {
    return cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
  };

  const estimatedPrice = (calculateTotal() / 100) * 9

  const cardInput = useRef()
  const secCode = useRef()
  const monthYear = useRef()
  const nameCard = useRef()

  const luhnCheck = (num) => {
    const digits = num.split('').reverse().map(digit => parseInt(digit, 10));
    let sum = 0;

    for (let i = 0; i < digits.length; i++) {
      let currentDigit = digits[i];

      if (i % 2 === 1) {
        currentDigit *= 2;
        if (currentDigit > 9) {
          currentDigit -= 9;
        }
      }

      sum += currentDigit;
    }

    return sum % 10 === 0;
  }

  function formatCreditCardNumber() {
    if (cardInput.current.value.length === 16 || cardInput.current.value.length === 19) {
      if (luhnCheck(cardInput.current.value.replace(/\s+/g, ''))) {
        setFormData({...formData, ['cardNumber']: "true"})
      } else {
        setFormData({...formData, ['cardNumber']: ""})
      }
    } else {
      setFormData({...formData, ['cardNumber']: ""})
    }
    
    cardInput.current.value = cardInput.current.value.replace(/\s+/g, '').replace(/[^\d]/g, "").replace(/(\d{4})(?=\d)/g, '$1 ')
  }

  function formatSecurityCode() {
    if (secCode.current.value.length >= 3) {
      setFormData({...formData,['cvv']: "true"})
    } else {
      setFormData({...formData,['cvv']: ""})
    }

    secCode.current.value = secCode.current.value.replace(/\s+/g, '').replace(/[^\d]/g, "")
  }

  function formatMmYy() {
    if (monthYear.current.value.length === 5) {
      setFormData({...formData,['expirationDate']: "true"})
    } else {
      setFormData({...formData,['expirationDate']: ""})
    }

    monthYear.current.value = monthYear.current.value.replace(/\s+/g, '').replace(/[^\d]/g, "").replace(/(\d{2})(?=\d)/g, '$1/')
  }

  // 

  const [isFormValid, setIsFormValid] = useState(false);

  const [formData, setFormData] = useState({
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    cardHolder: ''
  });

  useEffect(() => {
    const isValid = Object.values(formData).every(value => value.trim() !== '')
    setIsFormValid(isValid);
  }, [formData]); 

  return (
    <div>
      <div className='header__checkout-page'>
        <div className='header__checkout-container'>
          <img src={logo} />
          <h1 className='header__checkout-name-company'>Flowers Singapore</h1>
        </div>
      </div>
      <div className='section__selector-payment-page'>
        <div className='section__payment-detail-block'>
          <div className='payment-detail__block'>
            <h2 className='payment-detail__title'>Billing and shipping address</h2>
            <div className='payment-detail__inputs-block'>
              <InputDetail el={['Your Name', 'text']}/>
              <InputDetail el={['Your Mobile Number', 'text']} regular={true} format='mobile'/>
              <InputDetail el={['Delivery Date', 'date']} />
              <InputDetail el={['Delivery Time', 'text']} regular={/(\d{2})(?=\d)/g} format={true} max={5}/>
              <InputDetail el={["Recipient's Full Name", 'text']} />
              <InputDetail el={["Recipient's Mobile Number", 'text']} regular={true} format='mobile'/>
              <textarea className='payment-detail__inputs-textarea' placeholder='Full Delivery Address'></textarea>
            </div>
            <h2 className='payment-detail__title'>Payment</h2>
            <h3 className='payment-detail__description'>All transactions are secure and encrypted.</h3>
            <form method='post' action='/payment'>
              <input value={Number(calculateTotal() + estimatedPrice).toFixed(2)} style={{display: 'none'}} name='price'/>
              <div className='payment-detail__form-paymnent'>
                <div className='payment-detail__form-header'>
                  <h3 className='payment-detail__header-title'>Credit card</h3>
                  <div className='payment-detail__header-icon-block'>
                    <img src={visaIcon} draggable='false' />
                    <img src={masterIcon} draggable='false' />
                    <img src={amexIcon} draggable='false' />
                    <img src={unionIcom} draggable='false' />
                  </div>
                </div>
                <div className='payment-detail__form-body'>
                  <input ref={cardInput} onChange={() => {formatCreditCardNumber()}} maxLength={19} className='input-payment card' placeholder='Card number' name='cardNumber'/>
                  <div className='payment-detail__mini-block'>
                    <input ref={monthYear} onChange={() => {formatMmYy()}} maxLength={5} className='input-payment' placeholder='Expiration date (MM / YY)' name='expData'/>
                    <input ref={secCode} onChange={() => {formatSecurityCode()}} className='input-payment secur' placeholder='Security code' maxLength={4} name='cvv'/>
                  </div>
                  <input ref={nameCard} onChange={() => {setFormData({...formData,['cardHolder']: nameCard.current.value})}} className='input-payment' placeholder='Name on card' maxLength={80} name='cardHolder'/>
                </div>
              </div>
              <button className='payment-detail__button-pay' disabled={!isFormValid}>Pay now</button>
            </form>
          </div>
        </div>
        <div className='section__cart-detail-blcok'>
          <div className='cart-detail__block'>
            <h1 className='cart-detail__order-summary'>Order summary</h1>
            <div className='cart-detail__itmes-cart-block'>
              {
                cart.map((el) => {
                  return(<ItemCartDetail data={el} />)
                })
              }
            </div>
            <div className='payment__info-main-block'>
              <div className='payment__info-block-item'>
                <h3 className='payment__info-block-title'>Subtotal</h3>
                <h3 className='payment__info-block-descript'>{formatter.format(calculateTotal())}</h3>
              </div>
              <div className='payment__info-block-item'>
                <div className='payment__estimated-block'>
                  <h3 className='payment__info-block-title'>Estimated taxes</h3>
                  <img src={moreInfoIcon} />
                </div>
                <h3 className='payment__info-block-descript'>{formatter.format(estimatedPrice)}</h3>
              </div>
              <div className='payment__info-block-item total'>
                <h3 className='payment__info-block-title total'>Total</h3>
                <div className='payment__totoal-block-price'>
                  <h5 className='payment__currency'>SGD</h5>
                  <h3 className='payment__info-block-descript total'>{formatter.format(calculateTotal() + estimatedPrice)}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
